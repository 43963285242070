import React, { Suspense } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './routesConfig/PrivateRoute';
import { AppHeader } from "@coreui/react";
import authService from './services/authService';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';

import history from './services/history';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Saas = React.lazy(() => import("./views/Saas/Saas"));
const Places = React.lazy(() => import("./views/Saas/modulos/Locais/PlaceMain"));
const Times = React.lazy(() => import('./views/Saas/modulos/Times/TimeMain'));
const Colaboradores = React.lazy(() => import('./views/Saas/modulos/Times/ColaboradorMain'));
const Equipamentos = React.lazy(() => import('./views/Saas/modulos/Times/EquipmentMain'));
const Atividades = React.lazy(() => import('./views/Saas/modulos/Planejamentos/AtividadeMain'));
const AtividadePlanilha = React.lazy(() => import('./views/Saas/modulos/Planejamentos/AtividadePlanilha'));
const Alertas = React.lazy(() => import('./views/Saas/modulos/Alertas/AlertasMain'));
const DefaultHeader = React.lazy(() => import("./containers/DefaultLayout/DefaultHeader"));
const PlacesPlanilha = React.lazy(() => import("./views/Saas/modulos/Locais/PlacesPlanilha"));
const PlacesPlanilhaRemove = React.lazy(() => import("./views/Saas/modulos/Locais/PlacesPlanilhaRemove"));
const AccountPlanilha = React.lazy(() => import("./views/Saas/modulos/Times/AccountPlanilha"));
const AccountPlanilhaRemove = React.lazy(() => import("./views/Saas/modulos/Times/AccountPlanilhaRemove"));
const EquipmentPlanilha = React.lazy(() => import("./views/Saas/modulos/Times/EquipmentPlanilha"));
const EquipmentPlanilhaRemove = React.lazy(() => import("./views/Saas/modulos/Times/EquipmentPlanilhaRemove"));

const Layout = (props) => {
  const { child, clientId } = props;
  const loadingSpin = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  const username = authService.getUserName();
  const logout = () => {
    authService.logout();
    window.location.reload();
  }
  return (<div className="app" >
    <AppHeader fixed>
      <Suspense fallback={loadingSpin()}>
        <DefaultHeader username={username} logout={logout} clientId={clientId} />
      </Suspense>
    </AppHeader>
    <div className="app-body">
      <main className="main" style={{ marginTop: '10px' }}>
        <ReactNotification />
        {child}
      </main>
    </div>
  </div >)
}

const App = () => {
  return (

    <Router history={history}>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/login" name="Página de Login" component={props => <Login {...props} />} />

          <Route exact path="/404" name="Página 404" component={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Página 500" component={props => <Page500 {...props} />} />
          <PrivateRoute exact path="/locais" name="Places" component={props => <Layout child={<Places {...props}></Places>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/locais/planilha" name="PlacesPlanilha" component={props => <Layout child={<PlacesPlanilha {...props}></PlacesPlanilha>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/locais/planilha/remover" name="PlacesPlanilhaRemove" component={props => <Layout child={<PlacesPlanilhaRemove {...props}></PlacesPlanilhaRemove>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/equipes" name="Times" component={props => <Layout child={<Times {...props}></Times>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/colaboradores" name="Colaboradores" component={props => <Layout child={<Colaboradores {...props}></Colaboradores>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/colaboradores/planilha" name="ColaboradoresPlanilha" component={props => <Layout child={<AccountPlanilha {...props}></AccountPlanilha>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/colaboradores/planilha/remover" name="ColaboradoresPlanilhaRemove" component={props => <Layout child={<AccountPlanilhaRemove {...props}></AccountPlanilhaRemove>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/equipamentos" name="Equipamentos" component={props => <Layout child={<Equipamentos {...props}></Equipamentos>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/equipamentos/planilha" name="EquipamentosPlanilha" component={props => <Layout child={<EquipmentPlanilha {...props}></EquipmentPlanilha>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/equipamentos/planilha/remover" name="EquipamentosPlanilhaRemove" component={props => <Layout child={<EquipmentPlanilhaRemove {...props}></EquipmentPlanilhaRemove>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/planejamentos" name="Planejamentos" component={props => <Layout child={<Atividades {...props}></Atividades>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/planejamentos/planilha" name="PlanejamentosPlanilha" component={props => <Layout child={<AtividadePlanilha {...props}></AtividadePlanilha>} clientId={props.clientId}></Layout>} />
          <PrivateRoute exact path="/alertas" name="Alertas" component={props => <Layout child={<Alertas {...props}></Alertas>} clientId={props.clientId}></Layout>} />
          <PrivateRoute path="/" name="SaaS" component={props => <Layout child={<Saas {...props}></Saas>} clientId={props.clientId}></Layout>} />

          <PrivateRoute exact path="/places" name="Locais" component={props => (<Places></Places>)} />
        </Switch>
      </React.Suspense>
    </Router>

  );
}

export default App;
