import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import authService from '../services/authService';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const PrivateRoute = ({ component: Component, ...rest }) => {
    let query = useQuery();
    authService.setAuthTokenFromCookie();
    const isAdmin = authService.canAccessAnotherClientId();
    return isAdmin ? (
        <Route
            {...rest}
            render={props =>
                authService.isLoggedIn() ? (<Component {...props} clientId={query.get('clientId')} />) :
                    (<Redirect to={{
                        pathname: "/login",
                        state: { from: props.location },
                        search: `?clientId=${query.get('clientId')}`
                    }} />)
            }
        />) : (<Route
            {...rest}
            render={props =>
                authService.isLoggedIn() ? (<Component {...props} />) :
                    (<Redirect to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }} />)
            }
        />

        )

};