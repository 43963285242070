import endpoints from '../config/endpoints';
import jwt_decode from 'jwt-decode';
const authTokenName = 'authToken';

const canUserAccessThisEndpoint = () => {
    const token = localStorage.getItem(authTokenName);
    if (!token) return null;
    const decodedToken = jwt_decode(token);
    if (decodedToken.isAdmin) return true;
    const permissionToAccessThisEndpoint = decodedToken.endpoints.find(e => window.location.href.indexOf(e) >= 0);
    if (process.env.NODE_ENV !== 'production') return true;
    if (!permissionToAccessThisEndpoint) return false;
    return true;
}

const login = async ({ username, password }) => {
    try {
        const authenticateResponse = await fetch(
            endpoints.authenticationEndpoint,
            {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            });
        const authenticateData = await authenticateResponse.json();
        if (authenticateData.success) {
            const token = authenticateData.token.split("JWT")[1].trim();
            localStorage.setItem(authTokenName, token);
            return true;
        }
        return false;
    }
    catch (e) {
        console.error(e);
        return false;
    }
}

const isLoggedIn = () => {
    const token = localStorage.getItem(authTokenName);
    if (!token) return false;
    const userHasPermission = canUserAccessThisEndpoint();
    if (!userHasPermission) return false;
    return true;
}

const setAuthTokenFromCookie = () => {
    const token = localStorage.getItem(authTokenName);
    if (token) return;
    const cookieValues = document.cookie.split(';').map(c => ({ key: c.split('=')[0], value: c.split('=')[1] }));
    const session = cookieValues.find(c => c.key === 'session');
    if (session) localStorage.setItem(authTokenName, session.value);
}

const logout = () => {
    localStorage.removeItem(authTokenName);
}

const getUserName = () => {
    const token = localStorage.getItem(authTokenName);
    if (!token) return null;
    const decodedToken = jwt_decode(token);
    return decodedToken.username;
}

const canConfigAtividades = () => {
    const token = localStorage.getItem(authTokenName);
    if (!token) return null;
    const decodedToken = jwt_decode(token);
    if (decodedToken.isAdmin) return true;
    const configAtividadeContext = decodedToken.contexts.find(c => c.produtividade);
    if (!configAtividadeContext) return false;
    const isEnabled = configAtividadeContext.produtividade === 'true';
    return isEnabled;
}

const canConfigAlerts = () => {
    return true;
    // const token = localStorage.getItem(authTokenName);
    // if (!token) return null;
    // const decodedToken = jwt_decode(token);
    // if (decodedToken.isAdmin) return true;
    // const configRiscoContext = decodedToken.contexts.find(c => c.seguranca);
    // if (!configRiscoContext) return false;
    // const isEnabled = configRiscoContext.seguranca === 'true';
    // return isEnabled;
}

const canAccessAnotherClientId = () => {
    const token = localStorage.getItem(authTokenName);
    if (!token) return null;
    const decodedToken = jwt_decode(token);
    return decodedToken.isAdmin;
}


const isAdmin = () => {
    const token = localStorage.getItem(authTokenName);
    if (!token) return null;
    const decodedToken = jwt_decode(token);
    return decodedToken.isAdmin;
}


export default {
    login,
    logout,
    isLoggedIn,
    setAuthTokenFromCookie,
    getUserName,
    canConfigAtividades,
    canConfigAlerts,
    canAccessAnotherClientId,
    isAdmin,
}